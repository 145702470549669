import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useQuery } from "react-query";
import { useAuth } from "../../hooks/useAuth";
import eventBus from "../../utils/EventBus";
import { formatDate, formatNumberSystem } from "../../utils/helpers";
import { useEffectOnce } from "../../utils/hooks";
import { cacheTimes, queryActions } from "../../utils/reactQueryActions";
import { BackIcon, PrintIcon } from "../Icons";
import ModalLoader from "../utils/ModalLoader";
import { useStoreState } from "easy-peasy";

export default function FormPreviewModal({
  showFormPreviewModal,
  setShowFormPreviewModal,
  saveForm,
  template,
  selectedFilledForm,
  setSelectedFilledForm,
}) {
  const iframeRef = useRef();
  const { backendUrl, } = useAuth();
  const currentLoggedInCompany = useStoreState(
    (state) => state.currentLoggedInCompany
  );
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);

  const handleMessage = (message) => {
    //  console.log(message);
    if (message.data.type === "CLOSE") {
      setShowFormPreviewModal(false);
    } else if (message.data.type === "SAVE") {
      saveForm(message.data.value);
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    // cleanup this component
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  // fetch data for form
  const getDepartments = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/users/departments-with-users`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    data = { departments: [], staffs: [], jobGrades: [] },
    isFetching,
  } = useQuery(
    [queryActions.GET_USERS_WITH_DEPARTMENTS],
    () => getDepartments(),
    {
      enabled: true,
      keepPreviousData: true,
      cacheTime: cacheTimes.GET_USERS_WITH_DEPARTMENTS,
    }
  );

  const getForm = async () => {
    let response = await fetch(
      `${backendUrl}/api/forms/get-form/${selectedFilledForm?.FormID
        ? selectedFilledForm?.FormID
        : selectedFilledForm?.id
      }`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };
  const getFormQuery = useQuery(["GET_FORM"], () => getForm(), {
    enabled: true,
  });

  const setOrientation = (printOrientation) => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: "OREINTATION",
          data: {
            ...printOrientation,
          },
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  const sendSetupData = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          data: {
            ...data,
            template: template ? JSON.parse(template) : null,
            backendUrl,
            companyData: currentLoggedInCompany,
            formInfo: {
              formNumber: formatNumberSystem(
                selectedFilledForm?.formNumberPrefix,
                selectedFilledForm?.currentNumber
              ),
              revision: selectedFilledForm?.revision,
              documentNumber: selectedFilledForm?.documentNumber,
              date: formatDate(selectedFilledForm?.Date_Log, "MMM yyyy"),
            },
          },
          settings: { canFill: true, showTableCheckbox: true },
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  eventBus.useCustomEventListener(
    "SEND_FILE_TO_IFRAME",
    ({ files, elementId, elementType }) => {
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(
          {
            data: {
              files,
              elementId,
              elementType,
            },
            type: "FILES_FROM_PARENT",
          },
          process.env.REACT_APP_FORM_BUILDER_URL
        );
      }
    }
  );

  const printForm = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: "PRINT",
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  return (
    <>
      <Modal
        show={showFormPreviewModal}
        fullscreen={true}
        animation={false}
        onHide={() => {
          setShowFormPreviewModal(false);
          if (setSelectedFilledForm) setSelectedFilledForm(null);
        }}
      >
        <Modal.Header
          closeButton
          className="align-items-center justify-content-between gap-3"
        >
          <button
            onClick={() => setShowFormPreviewModal(false)}
            className="btn back-btn px-3  me-3"
          >
            <BackIcon />
          </button>
          <Modal.Title className="d-flex align-items-center gap-3">
            <h1 className="h5 mb-0">
              {formatNumberSystem(
                selectedFilledForm?.formNumberPrefix,
                selectedFilledForm?.currentNumber
              )}
              {" - "}
              {selectedFilledForm?.Title
                ? selectedFilledForm?.Title
                : selectedFilledForm?.Title}{" "}
              {" - "}
              {selectedFilledForm?.UserName
                ? selectedFilledForm?.UserName
                : selectedFilledForm?.UserName}
              {" - "}
              {selectedFilledForm?.Date_Log
                ? formatDate(selectedFilledForm.Date_Log)
                : "..."}{" "}
            </h1>{" "}
          </Modal.Title>
          {selectedFilledForm?.Description && (
            <p>{selectedFilledForm?.Description}</p>
          )}{" "}
          <div className="d-flex gap-3 align-items-center">
            <span>|</span>
            <Form.Group>
              {/*   <Form.Label>Orientation</Form.Label> */}
              {/*
              <div>
                <Form.Check
                  inline
                  label="Potrait"
                  value="Potrait"
                  name="orientation"
                  type={"radio"}
                  id="Potrait"
                  checked={printOrientation === "Potrait"}
                  onChange={(e) => setPrintOrientation(e.target.value)}
                />
                <Form.Check
                  inline
                  label="Landscape"
                  value="Landscape"
                  name="orientation"
                  type={"radio"}
                  id="Landscape"
                  checked={printOrientation === "Landscape"}
                  onChange={(e) => setPrintOrientation(e.target.value)}
                />
              </div> */}
            </Form.Group>
            <Button
              onClick={() => printForm()}
              className="d-flex align-items-center justify-content-center"
              title="Print"
              variant="outline-primary"
            >
              <PrintIcon />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body className="p-0">
          {!isFetching && !getFormQuery?.isFetching ? (
            <iframe
              ref={iframeRef}
              id="Frame"
              onLoad={(e) => {
                setIsLoadingIframe(false);
                sendSetupData();
                setOrientation({
                  orientation: getFormQuery.data.form.orientation,
                  pageSize: getFormQuery.data.form.pageSize,
                });
              }}
              className="w-100 h-100"
              title="Form Preview"
              src={`${process.env.REACT_APP_FORM_BUILDER_URL}/form/fill`}
            />
          ) : null}
        </Modal.Body>

        {/*  <Button onClick={(e) => sendSetupData()}>...</Button> */}
      </Modal>

      <ModalLoader
        show={isLoadingIframe || isFetching || getFormQuery?.isFetching}
      />
    </>
  );
}
